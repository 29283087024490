import './index.scss';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../../components/layout/wrapper';
import Table from '../../../../components/table';
import NoData from '../../../../components/no-data';
import api from '../../../../utils/api';
import Loading from '../../../../components/loading';
import { errToString, toPriceWithCurrency } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import AuthContext from '../../../../context/auth/auth-context';

function Customer() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const customerId = pathname.split('/')[2];

  const CustomersTitles = useMemo(
    () => [
      {
        name: t('routes.orders'),
      },
    ],
    [t]
  );

  useEffect(() => {
    api
      .get(`/customers/${customerId}`)
      .then((res) => {
        setCustomer(res.data.customer);
        setLoading(false);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setLoading(false);
      });
  }, [t, customerId]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            {!customer && (
              <p>
                <span onClick={() => navigate('/customers')}>
                  {t('routes.customers')}
                </span>
                / {t('general.customer')} ...
              </p>
            )}
            {customer && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                <p style={{ textTransform: 'capitalize' }}>
                  <span
                    style={{ textTransform: 'capitalize' }}
                    onClick={() => navigate('/customers')}
                  >
                    {t('routes.customers')}
                  </span>
                  / {customer.customer?.name || '-'}
                </p>
              </div>
            )}
          </div>
          {customer && (
            <div className='page_in_content'>
              <div className='shop_checkout_in'>
                <div
                  className='shop_checkout_in_info'
                  style={{ marginTop: 10 }}
                >
                  <span style={{ textTransform: 'lowercase' }}>
                    {t('general.phoneNumber')}:{' '}
                    {customer.customer?.phoneNumber || '-'}
                  </span>
                  <span style={{ textTransform: 'lowercase' }}>
                    {t('general.email')}: {customer.customer?.email || '-'}
                  </span>
                  <span>
                    {t('general.averageBill')}:{' '}
                    {toPriceWithCurrency(
                      Number(customer?.averageBill),
                      user.restaurant.restaurantInfo.currency
                    )}
                  </span>
                  <span>
                    {t('general.lastOrderDate')}:{' '}
                    {moment(customer?.last_order).format(`DD/MM/YYYY HH:mm`)}
                  </span>
                </div>
              </div>
              {!loading && !!customer?.invoices?.length && (
                <>
                  <Table
                    loading={false}
                    hiddenFields={['id']}
                    titles={CustomersTitles}
                    rows={
                      customer.invoices?.map((invoice, index) => ({
                        id: invoice.id,
                        order: (
                          <div key={index} className='page_in_content_table'>
                            <div className='page_in_content_table'>
                              <p>
                                {t('general.order')} #{invoice.internalId}
                              </p>
                              <p className='page_in_content_table_invoice'>
                                {t('components.tables.branch')}:{' '}
                                {invoice.branch.name}
                              </p>
                              <p className='page_in_content_table_price'>
                                {invoice.payments?.[0]?.payment_id === 'CARD'
                                  ? t('general.card')
                                  : t('general.cash')}
                              </p>
                              <p className='page_in_content_table_price'>
                                {toPriceWithCurrency(
                                  Number(
                                    invoice.orders.reduce(
                                      (acc, order) =>
                                        acc + order.price * order.quantity,
                                      0
                                    )
                                  ) +
                                    (Number(
                                      invoice.orders.reduce(
                                        (acc, order) =>
                                          acc + order.price * order.quantity,
                                        0
                                      )
                                    ) *
                                      invoice.branch.branchInfo.serviceFee) /
                                      100,
                                  user.restaurant.restaurantInfo.currency
                                )}
                              </p>
                              <p
                                className='page_in_content_table_date page_in_content_table_date--left'
                                style={{ paddingRight: '15px' }}
                              >
                                {moment(invoice.createdAt).format(
                                  `DD MMM YYYY, HH:mm`
                                )}
                              </p>
                            </div>
                          </div>
                        ),
                      })) || []
                    }
                  />
                </>
              )}
              {!loading && !customer.invoices.length && (
                <NoData title={t('noData.orders')} />
              )}
              {loading && <Loading />}
            </div>
          )}
          {!customer && (
            <div className='page_in_content'>
              {!loading && <NoData title={t('noData.customers')} />}
              {loading && <Loading />}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Customer);
