import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { LANGUAGES } from '../../../../../constants/general';
import Overlay from '../../../../../components/layout/overlay';
import { useLocation, useNavigate } from 'react-router-dom';

function ShopHeaderLng({ show, setShow, languages, setLngId, setLng }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const ref = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {show &&
        createPortal(
          <div
            ref={ref}
            className={cn('shop_header_in_actions_modal', {
              'shop_header_in_actions_modal--show': show,
            })}
            style={{
              top: 50,
              right: windowWidth >= 900 ? 510 : 10,
            }}
          >
            {languages.map((lng, index) => (
              <div
                key={index}
                className='shop_header_in_actions_modal_item'
                onClick={() => {
                  setLngId(lng.id);
                  setShow(false);
                  setLng(lng.name?.toLowerCase());
                  const systemLng = LANGUAGES.find(
                    ({ value }) => value === lng.name?.toLowerCase()
                  );
                  localStorage.removeItem('selectedCategoryTitle');

                  if (systemLng) {
                    i18n.changeLanguage(systemLng.value);
                    localStorage.setItem('lng', systemLng.value);
                  } else {
                    i18n.changeLanguage('us');
                    localStorage.setItem('lng', 'us');
                  }
                  return navigate(pathname.replace('#category-id-', ''));
                }}
              >
                <img
                  src={`https://hatscripts.github.io/circle-flags/flags/${lng.name?.toLowerCase()}.svg`}
                  alt={lng.name}
                />
                <p>{lng.name?.toUpperCase()}</p>
              </div>
            ))}
          </div>,
          document.getElementById('modal')
        )}
      {show && <Overlay isTransparent={true} onClick={() => setShow(false)} />}
    </>
  );
}

export default memo(ShopHeaderLng);
