import './index.scss';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import Pagination from '../../../components/pagination';
import { LIMITS } from '../../../constants/general';
import api from '../../../utils/api';
import Loading from '../../../components/loading';
import moment from 'moment/moment';
import { errToString } from '../../../utils';
import { useTranslation } from 'react-i18next';
import DropDown from '../../../components/drop-down';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';

function Reservations() {
  const { t } = useTranslation();

  const [reservations, setReservations] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(true);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState(null);

  const ReservationTitles = useMemo(
    () => [
      {
        name: t('general.dateAndTime'),
        size: 150,
      },
      {
        name: t('general.name'),
        size: 200,
      },
      {
        name: t('general.email'),
        size: 200,
      },
      {
        name: t('general.phoneNumber'),
        size: 200,
      },
      {},
    ],
    [t]
  );

  const onChangeLimit = useCallback(
    (option) => {
      if (option.value === limit.value) {
        return;
      }

      setLimit(option);
      setOffset(0);
      setLoading(true);
      setCurrentPage(0);
    },
    [limit]
  );

  const onRefresh = useCallback(() => {
    if (!selectedBranch) {
      return;
    }
    setLoading(true);

    api
      .get(
        `/tables/books/list?offset=${offset}&limit=${limit.value}&branchId=${selectedBranch.id}`
      )
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setLoading(false);
        setReservations(res.data);
      })
      .catch((err) => {
        //TODO error
        const audio = new Audio(sound);
        const info = `${err}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setLoading(false);
        if (errToString(err) === 'settings.primary.language.is.missing') {
          return setReservations([]);
        }
      });
  }, [limit, offset, selectedBranch]);

  const onBookingAction = useCallback(
    (reservation, status) => {
      setLoading(true);

      api
        .put(`/tables/book/table`, {
          tableOrderId: reservation.id,
          status: status,
        })
        .then(({ data }) => {
          onRefresh();
          setLoading(false);
          console.log(data);
        })
        .catch((err) => {
          console.log(err?.response?.data);
          setLoading(false);
        });
    },
    [onRefresh]
  );

  useEffect(() => {
    if (!selectedBranch) {
      return;
    }
    setLoading(true);

    api
      .get(
        `/tables/books/list?offset=${offset}&limit=${limit.value}&branchId=${selectedBranch.id}`
      )
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setLoading(false);
        setReservations(res.data);
      })
      .catch((err) => {
        //TODO error
        const audio = new Audio(sound);
        const info = `${err}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setLoading(false);
        if (errToString(err) === 'settings.primary.language.is.missing') {
          return setReservations([]);
        }
      });
  }, [t, limit, offset, selectedBranch]);
  useEffect(() => {
    api
      .get('/branches')
      .then(({ data }) => {
        setBranches(
          data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))
        );
        setSelectedBranch(
          data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
      })
      .catch((err) => {
        //TODO error
        console.log(errToString(err));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.reservations')}</h2>
            <div className='page_in_header_filter'>
              {branches && (
                <DropDown
                  placeholder={t('routes.branches')}
                  options={branches
                    .filter(({ deletedAt }) => !deletedAt)
                    .map((branch, index) => ({
                      option: branch,
                      el: <li key={index}>{branch.label}</li>,
                    }))}
                  value={selectedBranch}
                  onChange={(e) => setSelectedBranch(e)}
                  position='bottom'
                  loading={false}
                  showClose={false}
                />
              )}
            </div>
          </div>
          {!loading && (
            <div className='page_in_content'>
              {!!reservations?.length && (
                <>
                  <Table
                    route={false}
                    loading={false}
                    titles={ReservationTitles}
                    rows={
                      reservations?.map((reservation, index) => ({
                        date: (
                          <p>
                            {moment(reservation.bookDate).format(
                              'DD-MMM-YYYY HH:mm'
                            )}
                          </p>
                        ),
                        name: <p>{reservation.name}</p>,
                        email: <p>{reservation.email}</p>,
                        phone: <p>{reservation.phone}</p>,
                        actions: (
                          <div className='page_in_content_actions'>
                            <span
                              className={`page_in_content_table_status page_in_content_table_status--${reservation.status}`}
                              style={{ marginRight: '10px' }}
                            >
                              {t(`general.${reservation.status}`)}
                            </span>
                            {reservation.status !== 'inactive' &&
                              reservation.status !== 'cancel' && (
                                <>
                                  <button
                                    className='page_in_content_actions_btn page_in_content_actions_btn--accept'
                                    onClick={() =>
                                      onBookingAction(reservation, 'inactive')
                                    }
                                  >
                                    <IoMdCheckmark />
                                  </button>
                                  <button
                                    className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                                    onClick={() =>
                                      onBookingAction(reservation, 'cancel')
                                    }
                                  >
                                    <IoMdClose />
                                  </button>
                                </>
                              )}
                          </div>
                        ),
                      })) || []
                    }
                  />
                  {!!pagesCount && (
                    <div className='page_in_pagination'>
                      <Pagination
                        pagesCount={pagesCount}
                        setOffset={setOffset}
                        limit={limit}
                        limitPlaceholder={limit.label}
                        limits={LIMITS}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onChange={onChangeLimit}
                      />
                    </div>
                  )}
                </>
              )}
              {!reservations?.length && (
                <NoData title={`${t('noData.reservation')}`} />
              )}
            </div>
          )}
          {loading && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Reservations);
