import { memo, useCallback, useContext, useState } from 'react';
import './index.scss';
import { MdLogout } from 'react-icons/md';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/auth/auth-context';
import cn from 'classnames';
import Overlay from '../overlay';
import { useTranslation } from 'react-i18next';
import DropDown from '../../drop-down';
import { LANGUAGES } from '../../../constants/general';
import LogoNew from '../../../assets/logo/QRrobo white.png';
import { IoMdMenu } from 'react-icons/io';
import { FaCircleUser } from 'react-icons/fa6';

function PageHeader({ showMenu, setShowMenu }) {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [lng, setLng] = useState({
    label: LANGUAGES.find(({ value }) => value === i18n.language).label,
    value: i18n.language,
  });
  const [showModal, setShowModal] = useState(false);

  const onChangeLng = useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
      localStorage.setItem('lng', lng);
    },
    [i18n]
  );

  const onLogOut = useCallback(() => {
    localStorage.removeItem('token');
    authCtx.signOut();
    navigate('/auth');
  }, [navigate, authCtx]);

  return (
    <div className='page_header'>
      <div className='page_header_in'>
        <div className='page_header_in_burger'>
          <IoMdMenu onClick={() => setShowMenu(!showMenu)} />
          <img
            src={LogoNew}
            alt='logo'
            onClick={() => {
              setShowMenu(false);
              navigate('/');
            }}
          />
        </div>
        <div className='navigation_in_lng'>
          <DropDown
            placeholder={'Choose language'}
            position='bottom'
            showClose={false}
            options={LANGUAGES.map((lng, index) => ({
              option: lng,
              el: (
                <div className='navigation_in_lng_drop_down' key={index}>
                  <div className='navigation_in_lng_drop_down_img'>
                    <img
                      src={`https://hatscripts.github.io/circle-flags/flags/${lng.value}.svg`}
                      width='48'
                      alt={lng.value}
                    />
                  </div>
                  <h3 className='navigation_in_lng_drop_down_name'>
                    {lng.label}
                  </h3>
                </div>
              ),
            }))}
            theme={'lng'}
            value={lng}
            onChange={(e) => {
              setLng(e);
              onChangeLng(e.value);
            }}
          />
        </div>
        <button
          className='page_header_profile'
          onClick={() => setShowModal(!showModal)}
        >
          <FaCircleUser />
        </button>
        {createPortal(
          <div
            className={cn('page_header_modal', {
              'page_header_modal--show': showModal,
            })}
          >
            <h3>{authCtx.user.name}</h3>
            <button onClick={onLogOut}>
              <MdLogout /> {t('general.logout')}
            </button>
          </div>,
          document.getElementById('modal')
        )}
        {showModal && (
          <Overlay isTransparent={true} onClick={() => setShowModal(false)} />
        )}
      </div>
    </div>
  );
}

PageHeader.propTypes = {};
export default memo(PageHeader);
