import './index.scss';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import CategoryProduct from './category-product';
import api from '../../../../utils/api';
import Loading from '../../../../components/loading';
import EditCategory from './edit-category';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { BsThreeDots } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { MdDelete } from 'react-icons/md';
import Overlay from '../../../../components/layout/overlay';
import Warning from '../../../../components/warning';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import AddCategoryProduct from './add-category-product';
import { RiDragMove2Line } from 'react-icons/ri';
import { IoMdAddCircle } from 'react-icons/io';
import { MdRestoreFromTrash } from 'react-icons/md';
import { IoIosCloseCircle } from 'react-icons/io';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function Category({
  category,
  categories,
  setCategories,
  selectedLngId,
  selectedLng,
  hasDefaultIntegration,
  index,
  primaryLngId,
  type = 'default',
  labels,
}) {
  const { t } = useTranslation();
  const morePopupRef = useRef({});
  const [products, setProducts] = useState([]);
  const [categoryIsEditing, setCategoryIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collapse, setCollapse] = useState(true);
  const [showMore, setShowMore] = useState(null);
  const [warning, setWarning] = useState(false);
  const [addProductFormIsOpen, setAddProductFormIsOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(null);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);
  const [dragSourceIndex, setDragSourceIndex] = useState(null);
  const [reordering, setReordering] = useState(false);
  const [loadingReorder, setLoadingReorder] = useState(false);

  const shouldShowLoadMoreBtn = useMemo(() => {
    if (!products) {
      return;
    }

    return !(products.length >= count);
  }, [count, products]);

  const onRefresh = useCallback(() => {
    api
      .get(
        `/products?categoryId=${category.id}&languageId=${selectedLngId}&offset=0&limit=${limit}&freezed`
      )
      .then(({ data }) => {
        setLoading(false);
        return setProducts(
          data.results.sort((a, b) => a.name.localeCompare(b.name))
        );
      })
      .catch((err) => {
        //TODO error
        setLoading(false);
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [limit, t, category.id, selectedLngId]);
  const onCategoryFreeze = useCallback(() => {
    api
      .delete(`/product-categories/${category.id}`)
      .then(() => {
        setWarning(false);
        setShowMore(false);
        onRefresh();
      })
      .catch((err) => {
        //TODO error
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [onRefresh, t, category]);
  const onCategoryUnFreeze = useCallback(() => {
    api
      .put(`/product-categories/${category.id}`, {
        restore: true,
        languageId: primaryLngId,
      })
      .then(() => {
        setShowMore(false);
        onRefresh();
      })
      .catch((err) => {
        //TODO error
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [onRefresh, t, category, primaryLngId]);
  const onDeleteCategory = useCallback(() => {
    api
      .put(`product-categories/${category.id}`, {
        isHidden: true,
        languageId: primaryLngId,
      })
      .then(() => {
        setCategories(categories.filter(({ id }) => id !== category.id));
        const audio = new Audio(sound);
        const info = t('notifications.deleteCategory');
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
      })
      .catch((err) => {
        //TODO error
        const audio = new Audio(sound);
        const info = errToString(err);
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'warning',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [categories, category.id, setCategories, t, primaryLngId]);
  const handleDragStart = useCallback((e, index) => {
    e.dataTransfer.setData('text/plain', index);
    setDragSourceIndex(index);
  }, []);
  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      setDropTargetIndex(index);
    },
    [index]
  );
  const handleDragLeave = useCallback(() => {
    setDropTargetIndex(null);
  }, []);
  const handleDrop = useCallback(
    (e, targetIndex) => {
      e.preventDefault();
      setDropTargetIndex(null);

      const sourceIndex = e.dataTransfer.getData('text/plain');
      const newObjects = [...categories];

      const [draggedObject] = newObjects.splice(sourceIndex, 1);
      newObjects.splice(targetIndex, 0, draggedObject);
      setCategories(newObjects);
    },
    [setCategories, categories]
  );
  const handleDragEnd = useCallback(() => {
    setDragSourceIndex(null);
  }, []);

  const onSubmitProductsReorder = useCallback(() => {
    let resultObject = {};
    const updatedObjects = [...products.map(({ id }) => id)];
    updatedObjects.forEach(
      (number, index) => (resultObject[number] = index + 1)
    );
    setLoadingReorder(true);

    api
      .put(`/products/sort`, resultObject)
      .then(() => {
        setLoadingReorder(false);
        setReordering(false);
        setLimit(10);
      })
      .catch((err) => {
        //TODO error
        console.error('Error updating positions:', errToString(err));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setLoadingReorder(false);
      });
  }, [products]);

  useEffect(() => {
    if (type !== 'default') {
      return;
    }
    api
      .get(
        `/products?categoryId=${category.id}&languageId=${selectedLngId}&offset=0&limit=${limit}&freezed`
      )
      .then(({ data }) => {
        setLoading(false);
        setCount(data.count);
        return setProducts(data.results);
      })
      .catch((err) => {
        //TODO error
        setLoading(false);
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [type, limit, t, category.id, selectedLngId]);

  return (
    <>
      {type === 'default' && (
        <div className='category' onClick={() => setCollapse(!collapse)}>
          <div className='category_header'>
            <h2 className='category_header_name'>{category.name}</h2>
            <div className='category_header_wrapper'>
              <div
                className='category_header_icon'
                onClick={(e) => {
                  e.stopPropagation();
                  setCategoryIsEditing(true);
                }}
              >
                <FiEdit />
              </div>
              <div
                ref={(el) => (morePopupRef.current[category.id] = el)}
                className='category_header_icon'
                onClick={(e) => {
                  e.stopPropagation();

                  if (showMore === category.id) {
                    return setShowMore(null);
                  }
                  return setShowMore(category.id);
                }}
              >
                <BsThreeDots />
                {showMore === category.id &&
                  morePopupRef &&
                  createPortal(
                    <>
                      <div
                        className={cn('page_in_content_actions_popup', {
                          'page_in_content_actions_popup--show': showMore,
                        })}
                        style={{
                          top:
                            morePopupRef.current[
                              category.id
                            ].getBoundingClientRect().top + 40,
                          right: 80,
                          width: '300px',
                        }}
                      >
                        {!hasDefaultIntegration && (
                          <button
                            className='page_in_content_actions_more_btn'
                            onClick={() => setAddProductFormIsOpen(true)}
                          >
                            <IoMdAddCircle />
                            {t('general.addProduct')}
                          </button>
                        )}
                        <button
                          className='page_in_content_actions_more_btn'
                          onClick={onDeleteCategory}
                        >
                          <IoIosCloseCircle />
                          {t('general.deleteCategory')}
                        </button>
                        <button
                          className='page_in_content_actions_more_btn'
                          onClick={onCategoryUnFreeze}
                        >
                          <MdRestoreFromTrash />
                          {t('general.unFreezeCategory')}
                        </button>
                        <button
                          className='page_in_content_actions_more_btn'
                          onClick={() => setWarning(true)}
                        >
                          <MdDelete />
                          {t('general.freezeCategory')}
                        </button>
                        {!!products?.length && (
                          <button
                            className='page_in_content_actions_more_btn'
                            onClick={() => {
                              setReordering(true);
                              setLimit(count);
                            }}
                          >
                            <RiDragMove2Line />
                            {t('general.reorderProducts')}
                          </button>
                        )}
                      </div>
                      <Overlay
                        isTransparent={true}
                        onClick={() => setShowMore(null)}
                      />
                    </>,
                    document.getElementById('modal')
                  )}
              </div>
              <div
                className='category_header_icon'
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
            </div>
          </div>
          {reordering && (
            <div className='category_content'>
              {!!products?.length &&
                createPortal(
                  <div
                    className='menu_modal menu_modal--active'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className='menu_modal_title'>
                      {t('general.reorderProducts')}
                    </div>
                    <div className='menu_modal_in'>
                      {!loadingReorder &&
                        products.map((product, index) => (
                          <CategoryProduct
                            key={product.id}
                            name={product.name}
                            index={index}
                            category={category}
                            categoryName={category.name}
                            price={product.price}
                            id={product.id}
                            isActive={!product.deletedAt}
                            selectedLngId={selectedLngId}
                            selectedLng={selectedLng}
                            onRefresh={onRefresh}
                            hasDefaultIntegration={hasDefaultIntegration}
                            setProducts={setProducts}
                            products={products}
                            setDropCategoryTargetIndex={setDropTargetIndex}
                            primaryLngId={primaryLngId}
                            type={'grap'}
                            labels={labels}
                          />
                        ))}
                      {loadingReorder && (
                        <div className='menu_modal_in_loading'>
                          <Loading />
                        </div>
                      )}
                    </div>
                    <div className='menu_modal_actions'>
                      <button
                        className='menu_modal_actions_btn menu_modal_actions_btn--cancel'
                        onClick={() => {
                          setReordering(false);
                          setCategories(categories);
                          setLimit(10);
                          onRefresh();
                        }}
                      >
                        {t('general.cancel')}
                      </button>
                      <button
                        className='menu_modal_actions_btn menu_modal_actions_btn--submit'
                        onClick={onSubmitProductsReorder}
                      >
                        {t('general.save')}
                      </button>
                    </div>
                    <Overlay
                      onClick={() => {
                        setReordering(false);
                        setLimit(10);
                        onRefresh();
                      }}
                    />
                  </div>,
                  document.getElementById('modal')
                )}
            </div>
          )}
          {!collapse && (
            <div className='category_content'>
              {!loading && (
                <>
                  {!!products.length &&
                    !reordering &&
                    products.map((product, index) => (
                      <CategoryProduct
                        key={product.id}
                        name={product.name}
                        category={category}
                        index={index}
                        categoryName={category.name}
                        price={product.price}
                        id={product.id}
                        isActive={!product.deletedAt}
                        selectedLngId={selectedLngId}
                        selectedLng={selectedLng}
                        onRefresh={onRefresh}
                        hasDefaultIntegration={hasDefaultIntegration}
                        setProducts={setProducts}
                        products={products}
                        setDropCategoryTargetIndex={setDropTargetIndex}
                        primaryLngId={primaryLngId}
                        type={'default'}
                        labels={labels}
                      />
                    ))}
                  {shouldShowLoadMoreBtn && (
                    <button
                      className='category_content_loadmore'
                      onClick={(e) => {
                        e.stopPropagation();
                        setLimit(count);
                      }}
                    >
                      {t('general.loadAllProducts')}
                    </button>
                  )}
                  {!products.length && (
                    <div className='category_content_empty'>
                      <p className='category_content_empty_txt'>
                        {t('noData.products')}
                      </p>
                      {!hasDefaultIntegration && (
                        <button
                          className='category_content_empty_btn'
                          onClick={() => setAddProductFormIsOpen(true)}
                        >
                          {t('general.addProduct')}
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
              {loading && <Loading />}
            </div>
          )}
          {addProductFormIsOpen && (
            <AddCategoryProduct
              branchId={category.branchId}
              lngId={selectedLngId}
              lng={selectedLng}
              setAddProductFormIsOpen={setAddProductFormIsOpen}
              onRefresh={onRefresh}
              selectedCategory={category}
            />
          )}
          {warning && (
            <Warning
              description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${category.name}`}
              onCancel={() => {
                setWarning(false);
                setShowMore(false);
              }}
              onSubmit={onCategoryFreeze}
            />
          )}
          {categoryIsEditing && (
            <EditCategory
              selectedCategory={category}
              setCategoryIsEditing={setCategoryIsEditing}
              setCategories={setCategories}
              categories={categories}
              selectedLng={selectedLng}
              selectedLngId={selectedLngId}
            />
          )}
        </div>
      )}
      {type !== 'default' && (
        <div
          draggable
          className={cn(
            'category_product',
            { 'category_product--highlighted': index === dropTargetIndex },
            { 'category_product--dragging': index === dragSourceIndex }
          )}
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={() => handleDragLeave()}
          onDragEnd={() => handleDragEnd()}
          onDrop={(e) => handleDrop(e, index)}
        >
          <div className='category_product_reorder' style={{ cursor: 'grab' }}>
            <PiDotsSixVerticalBold />
          </div>
          <div className='category_product_in'>
            <h2 className='category_product_in_name'>{category.name}</h2>
          </div>
        </div>
      )}
    </>
  );
}

Category.propTypes = {
  category: PropTypes.object.isRequired,
  setCategories: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  src: PropTypes.string,
  selectedLngId: PropTypes.number.isRequired,
  selectedLng: PropTypes.string.isRequired,
  hasDefaultIntegration: PropTypes.object,
};

export default memo(Category);
