import './index.scss';
import { memo, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import { errToString, toPriceWithCurrency } from '../../../../utils';
import moment from 'moment/moment';
import api from '../../../../utils/api';
import Warning from '../../../../components/warning';
import OrdersListProduct from './orders-list-product';
import { useTranslation } from 'react-i18next';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import AuthContext from '../../../../context/auth/auth-context';

function OrdersList({
  type,
  invoices,
  setInvoices,
  setShowOrders,
  invoice,
  onRefresh,
  setSelectedInvoice,
}) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [warning, setWarning] = useState(false);

  const onPay = useCallback(() => {
    if (type !== 'live') {
      return;
    }

    api
      .delete(`/tables/${invoice.table.id}/close?status=paid`, {
        status: 'paid',
      })
      .then(() => {
        setWarning(false);
        setShowOrders(false);

        const audio = new Audio(sound);
        const info = `${invoice.internalId} ${t('notifications.payInvoice')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        onRefresh();
        return setInvoices(
          invoices.map((_invoice) => {
            if (_invoice.id === invoice.id) {
              return {
                ...invoice,
                status: 'paid',
              };
            }
            return _invoice;
          })
        );
      })
      .catch((err) => {
        //TODO error
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [onRefresh, t, type, setShowOrders, setInvoices, invoice, invoices]);

  return createPortal(
    <div
      className='orders_list'
      onClick={() => {
        setShowOrders(false);
      }}
    >
      <div
        className='orders_list_in orders_list_in--active'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='orders_list_header'>
          <button
            className={`page_in_content_status page_in_content_status--${invoice.status?.toLowerCase()}`}
          >
            {t(`general.${invoice.status}`)}
          </button>
          <h2>
            #{invoice.internalId} -{' '}
            {moment(invoice.createdAt).format(`DD MMM YY, hh:mm`)}
          </h2>
          <i
            onClick={() => {
              setShowOrders(false);
            }}
          >
            <IoMdClose />
          </i>
        </div>
        <div className='orders_list_details'>
          <div className='orders_list_details_in'>
            <div className='shop_checkout_in_info'>
              <span>
                {t('shop.area')}: {invoice.table.area.name}
              </span>
              <span>
                {t('shop.table')}: {invoice.table.name}
              </span>
              <span>
                {t('components.tables.branch')}: {invoice.branch.name}
              </span>
              <span>
                {t('shop.payment')}:{' '}
                {invoice.payments?.[0]?.payment_id === 'CARD'
                  ? t('general.card')
                  : t('general.cash')}
              </span>
              <span>
                {t('components.tables.createdDate')}:{' '}
                {moment(invoice.createdAt).format(`DD MMM YYYY`)}
              </span>
            </div>
            {invoice.totalAmount === 0 && (
              <h2 className='orders_list_details_in_title orders_list_details_in_title--light'>
                {t('noData.orders')}
              </h2>
            )}
            {invoice.totalAmount !== 0 && (
              <h2 className='orders_list_details_in_title'>
                {t('shop.billInfo')}
              </h2>
            )}
            <div className='orders_list_details_in_bill'>
              {invoice.orders.map((order, index) => (
                <div key={index} className='orders_list_details_in_bill_order'>
                  {!!order?.products?.length && (
                    <>
                      <div className='shop_checkout_in_order_guest'>
                        <div className='shop_checkout_in_order_guest_btn'>
                          {type === 'live' &&
                            order.guest?.externalId &&
                            `${t('components.orderList.guest')} ${order.guest?.externalId}`}
                          {type === 'live' &&
                            !order.guest?.externalId &&
                            `${t('components.orderList.waiter')}`}
                          {type !== 'live' &&
                            `${t('components.orderList.order')} ${order.id}`}
                        </div>
                        <p>
                          {toPriceWithCurrency(
                            Number(order.totalAmount),
                            user.restaurant.restaurantInfo.currency
                          )}
                        </p>
                      </div>
                      <ul className='orders_list_details_in_bill_order_list'>
                        {order.products.map((product, index) => (
                          <OrdersListProduct
                            type={type}
                            product={product}
                            index={index}
                            key={index}
                            tableId={invoice.table.id}
                            onRefresh={onRefresh}
                            setInvoices={setInvoices}
                            setSelectedInvoice={setSelectedInvoice}
                          />
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
              {invoice.totalAmount !== 0 && (
                <>
                  <ul className='orders_list_details_in_bill_subtotal'>
                    <li>
                      <span>{t('components.orderList.subtotal')}:</span>
                      <p>
                        {toPriceWithCurrency(
                          Number(invoice.totalAmount),
                          user.restaurant.restaurantInfo.currency
                        )}
                      </p>
                    </li>
                    <li>
                      <span>{`${t('components.orderList.serviceFee')} (${invoice.branch.branchInfo.serviceFee}%):`}</span>
                      <p>
                        {toPriceWithCurrency(
                          Number(
                            (invoice.totalAmount *
                              invoice.branch.branchInfo.serviceFee) /
                              100
                          ),
                          user.restaurant.restaurantInfo.currency
                        )}
                      </p>
                    </li>
                  </ul>
                  <div className='orders_list_details_in_bill_total'>
                    <span className='orders_list_details_in_bill_total_span'>
                      {t('components.orderList.total')}:
                    </span>
                    <p>
                      {toPriceWithCurrency(
                        Number(
                          Number(
                            (invoice.totalAmount *
                              invoice.branch.branchInfo.serviceFee) /
                              100
                          ) + Number(invoice.totalAmount)
                        ),
                        user.restaurant.restaurantInfo.currency
                      )}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          {type === 'live' && invoice.totalAmount !== 0 && (
            <div className='orders_list_details_actions'>
              <button
                className='orders_list_details_actions_btn'
                onClick={() => setWarning(true)}
              >
                {t('general.pay')}
              </button>
            </div>
          )}
        </div>
      </div>
      {warning && type === 'live' && (
        <Warning
          description={`${t('components.warning.mainTxt')} ${t('components.warning.pay')} invoice #${invoice.internalId}`}
          onCancel={() => setWarning(false)}
          onSubmit={onPay}
        />
      )}
    </div>,
    document.getElementById('modal')
  );
}

export default memo(OrdersList);
