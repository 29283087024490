import './index.scss';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import ShopHeader from './shop-header';
import ShopCategories from './shop-categories';
import { useLocation } from 'react-router-dom';
import api from '../../../utils/shop-api';
import Loading from '../../../components/loading';
import Overlay from '../../../components/layout/overlay';
import { errToString } from '../../../utils';
import { LANGUAGES } from '../../../constants/general';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ShopBasket from '../shop-basket';
import ShopContext from '../../../context/shop/shop-context';
import { toast } from 'react-toastify';
import sound from '../../../assets/sounds/notification.wav';
import MenuContext from '../../../context/menu/menu-context';

function ShopHome() {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { products: basketProducts } = useContext(ShopContext);
  const { set } = useContext(MenuContext);

  const branchId = pathname.split('/')[2];
  const tableId = pathname.split('/')[4];
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [lngId, setLngId] = useState(null);
  const [lng, setLng] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [showLanguageDropDown, setShowLanguageDropDown] = useState(false);
  const [showSignUpPage, setShowSignUpPage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const totalCount = useMemo(
    () => basketProducts.reduce((acc, val) => acc + val.qty, 0),
    [basketProducts]
  );
  const totalAmount = useMemo(
    () =>
      basketProducts.reduce((acc, val) => {
        return (
          acc +
          val.price * val.qty +
          val.options.reduce((acc, val) => acc + val.price, 0) * val.qty
        );
      }, 0),
    [basketProducts]
  );

  useEffect(() => {
    api
      .get(`/shop/${branchId}/languages`)
      .then((res) => {
        setLngId(
          res.data.filter(({ isPrimary }) => isPrimary).map(({ id }) => id)[0]
        );
        setLng(
          res.data
            .filter(({ isPrimary }) => isPrimary)
            .map(({ name }) => name)[0]
        );

        const systemLng = LANGUAGES.find(({ value }) => {
          return (
            value?.toLowerCase() ===
            res.data
              .filter(({ isPrimary }) => isPrimary)
              .map(({ name }) => name)[0]
              ?.toLowerCase()
          );
        });

        if (systemLng) {
          i18n.changeLanguage(systemLng.value);
        } else {
          i18n.changeLanguage('us');
        }

        return setLanguages(res.data);
      })
      .catch((err) => console.log(errToString(err)));
  }, [branchId, i18n]);
  useEffect(() => {
    if (!lngId) {
      return setLoading(true);
    }

    api
      .get(`/shop/${branchId}/product-categories?languageId=${lngId}`)
      .then((res) => {
        return setCategories(res.data);
      })
      .catch((err) => {
        console.log(errToString(err));
      });
  }, [lngId, branchId]);
  useEffect(() => {
    if (!lngId) {
      return setLoading(true);
    }

    api
      .get(
        `/shop/${branchId}/list/categories-products?offset=0&limit=10000&languageId=${lngId}`
      )
      .then(({ data }) => {
        set(data);
        setLoading(false);
      })
      .catch((err) => {
        //TODO error
        setLoading(true);
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });

    // eslint-disable-next-line
  }, [branchId, lngId]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (pathname.split('/')[5] === 'success') {
      const info = 'Thank you! your payment was successfully received!';
      toast.info(info, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    if (pathname.split('/')[5] === 'error') {
      const info =
        'Oops. There was an error processing with your payment. Please contact your bank provider';
      toast.info(info, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        type: 'error',
      });
    }
  }, [pathname]);

  return (
    <>
      <div
        className={cn('shop_home', { 'shop_home--short': windowWidth > 900 })}
      >
        <ShopHeader
          branchId={branchId}
          tableId={tableId}
          lng={lng}
          lngId={lngId}
          setLng={setLng}
          setLngId={setLngId}
          showLanguageDropDown={showLanguageDropDown}
          setShowLanguageDropDown={setShowLanguageDropDown}
          languages={languages}
          isDarkMode={false}
          setShowSignUpPage={setShowSignUpPage}
          showSignUpPage={showSignUpPage}
        />
        {lngId && !loading && (
          <ShopCategories
            branchId={branchId}
            categories={categories}
            tableId={tableId}
            lngId={lngId}
            isDarkMode={false}
          />
        )}
        {(!lngId || loading) && (
          <div
            className={cn('shop_checkout_loading', {
              'shop_checkout_loading--dark': true,
            })}
          >
            <Overlay isTransparent={true} />
            <Loading />
          </div>
        )}
      </div>
      {windowWidth > 900 && !showSignUpPage && (
        <ShopBasket
          branchId={branchId}
          isDarkMode={false}
          products={basketProducts}
          setBasketIsOpen={() => {}}
          totalCount={totalCount}
          totalAmount={totalAmount}
          basketIsOpen={true}
          lngId={lngId}
          type={'fullWidth'}
        />
      )}
    </>
  );
}

export default memo(ShopHome);
