import { memo, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { NAVIGATION_ROUTES } from '../../constants/navigation-routes';
import './index.scss';
import LogoNew from '../../assets/logo/QRrobo white.png';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';

function Navigation({ showMenu, setShowMenu }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const route = useMemo(
    () => pathname.split('/').slice(0, 3).join('/'),
    [pathname]
  );

  return createPortal(
    <>
      <nav className={cn('navigation', { 'navigation--open': showMenu })}>
        <div className='navigation_in'>
          <div className='navigation_in_burger'>
            <IoMdClose onClick={() => setShowMenu(!showMenu)} />
            <img
              src={LogoNew}
              alt='logo'
              onClick={() => {
                navigate('/');
                setShowMenu(false);
              }}
            />
          </div>
          <ul>
            {NAVIGATION_ROUTES.map(({ id, links, icon, color }) => (
              <li
                key={id}
                className={cn('navigation_list', {
                  'navigation_list--active': links.includes(route),
                })}
              >
                <Link to={links[0]} onClick={() => setShowMenu(!showMenu)}>
                  <div className='navigation_list_icon' style={{ color }}>
                    {icon}
                  </div>
                  {t(`routes.${id}`)}
                  {links.includes('/') && (
                    <span className='navigation_list_icon--live' />
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>,
    document.getElementById('modal')
  );
}

Navigation.propTypes = {};

export default memo(Navigation);
