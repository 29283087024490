import React, { memo, useCallback, useState } from 'react';
import './index.scss';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import LanguageDropDown from '../../../../components/language-drop-down';
import api from '../../../../utils/api';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function EditLanguage({
  setEditableLanguage,
  editableLanguage,
  setCreatedLanguages,
  setSelectedLng,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(
    editableLanguage.name?.toUpperCase()
  );
  const [isPrimary, setIsPrimary] = useState(false);
  const [error, setError] = useState(null);

  const onEditLanguage = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      const data = {
        name: selected,
      };

      if (isPrimary) {
        data.isPrimary = true;
      }

      api
        .put(`restaurant/languages/${editableLanguage.id}`, data)
        .then((res) => {
          setCreatedLanguages(res.data);
          setEditableLanguage(null);
          setSelectedLng(null);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          //TODO error
        });
    },
    [
      t,
      setSelectedLng,
      isPrimary,
      setEditableLanguage,
      setCreatedLanguages,
      selected,
      editableLanguage,
    ]
  );

  return createPortal(
    <div className='edit_language'>
      <div className='edit_language_in edit_language_in--active'>
        <div className='edit_language_in_header'>
          <h2>{t('general.editLanguage')}</h2>
          <i onClick={() => setEditableLanguage(null)}>
            <IoMdClose />
          </i>
        </div>
        <form className='form' onSubmit={onEditLanguage}>
          <div className='form_columns'>
            <div className='form_column'>
              <LanguageDropDown
                selected={selected}
                setSelected={setSelected}
                searchable={true}
                className={'lng_drop_down'}
                countries={''}
              />
            </div>
          </div>
          {!editableLanguage.isPrimary && (
            <div className='form_columns'>
              <div className='form_column'>
                <div className='edit_language_in_checkbox'>
                  <p onClick={() => setIsPrimary(!isPrimary)}>
                    {t('dashboard.menu.primaryLngQuestion')}
                  </p>
                  <input
                    id={editableLanguage.id}
                    type='checkbox'
                    checked={isPrimary}
                    onChange={() => setIsPrimary(!isPrimary)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
              {t('general.edit')}
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

export default memo(EditLanguage);
