import './index.scss';
import { memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { AiFillStar } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../../utils/api';
import { errToString } from '../../../../utils';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function CheckoutReview({ invoiceId }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const tableId = pathname.split('/')[4];
  const branchId = pathname.split('/')[2];

  const [star1, setStar1] = useState(true);
  const [star2, setStar2] = useState(true);
  const [star3, setStar3] = useState(true);
  const [star4, setStar4] = useState(true);
  const [star5, setStar5] = useState(true);
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState('');

  const onSubmitRating = useCallback(() => {
    const data = { rating: Number(rating) };
    if (review) {
      data.review = review;
    }

    api
      .put(`/invoices/${invoiceId}/review`, data)
      .then((res) => {
        const audio = new Audio(sound);
        const info = t('shop.reviewSuccess');
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();

        if (res.data.redirectUrl) {
          return window.location.replace(res.data.redirectUrl);
        } else {
          return navigate(`/shop/${branchId}/tables/${tableId}`);
        }
      })
      .catch((err) => {
        console.log(errToString(err));
      });
  }, [t, invoiceId, branchId, rating, review, navigate, tableId]);

  return (
    <>
      <div className='shop_checkout_rating'>
        <div className='shop_checkout_rating_in'>
          <div
            className={cn('shop_checkout_rating_stars', {
              'shop_checkout_rating_stars--active': star1,
            })}
            onClick={() => {
              setStar1(false);
              setStar2(false);
              setStar3(false);
              setStar4(false);
              setStar5(false);
              setStar1(true);
              setRating(1);
            }}
          >
            <AiFillStar />
          </div>
          <div
            className={cn('shop_checkout_rating_stars', {
              'shop_checkout_rating_stars--active': star1 && star2,
            })}
            onClick={() => {
              setStar1(false);
              setStar2(false);
              setStar3(false);
              setStar4(false);
              setStar5(false);
              setStar1(true);
              setStar2(true);
              setRating(2);
            }}
          >
            <AiFillStar />
          </div>
          <div
            className={cn('shop_checkout_rating_stars', {
              'shop_checkout_rating_stars--active': star1 && star2 && star3,
            })}
            onClick={() => {
              setStar1(false);
              setStar2(false);
              setStar3(false);
              setStar4(false);
              setStar5(false);
              setStar1(true);
              setStar2(true);
              setStar3(true);
              setRating(3);
            }}
          >
            <AiFillStar />
          </div>
          <div
            className={cn('shop_checkout_rating_stars', {
              'shop_checkout_rating_stars--active':
                star1 && star2 && star3 && star4,
            })}
            onClick={() => {
              setStar1(false);
              setStar2(false);
              setStar3(false);
              setStar4(false);
              setStar5(false);
              setStar1(true);
              setStar2(true);
              setStar3(true);
              setStar4(true);
              setRating(4);
            }}
          >
            <AiFillStar />
          </div>
          <div
            className={cn('shop_checkout_rating_stars', {
              'shop_checkout_rating_stars--active':
                star1 && star2 && star3 && star4 && star5,
            })}
            onClick={() => {
              setStar1(false);
              setStar2(false);
              setStar3(false);
              setStar4(false);
              setStar5(false);
              setStar1(true);
              setStar2(true);
              setStar3(true);
              setStar4(true);
              setStar5(true);
              setRating(5);
            }}
          >
            <AiFillStar />
          </div>
        </div>
        <div className='shop_checkout_rating_review'>
          <label htmlFor='review'>Leave your review here</label>
          <textarea
            name='review'
            autoComplete='off'
            placeholder='Leave your review here'
            value={review}
            maxLength={150}
            onChange={(e) => setReview(e.target.value)}
          />
        </div>
        <button className='shop_checkout_rating_btn' onClick={onSubmitRating}>
          Submit
        </button>
      </div>
    </>
  );
}

export default memo(CheckoutReview);
