import './index.scss';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import api from '../../../utils/api';
import Loading from '../../../components/loading';
import { errToString } from '../../../utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LIMITS } from '../../../constants/general';
import Pagination from '../../../components/pagination';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function Customers() {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const CustomersTitles = useMemo(
    () => [
      {
        name: t('components.tables.name'),
        size: 200,
      },
      {
        name: t('components.tables.email'),
        size: 200,
      },
      {
        name: t('general.phoneNumber'),
        size: 200,
      },
      {
        name: t('components.tables.createdAt'),
      },
    ],
    [t]
  );

  const onChangeLimit = useCallback(
    (option) => {
      if (option.value === limit.value) {
        return;
      }

      setLimit(option);
      setOffset(0);
      setLoading(true);
      setCurrentPage(0);
    },
    [limit]
  );

  useEffect(() => {
    api
      .get(`/customers?offset=${offset}&limit=${limit.value}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setCustomers(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        //TODO error
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setLoading(false);
      });
  }, [limit.value, offset, t]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.customers')}</h2>
          </div>
          {!loading && (
            <div className='page_in_content'>
              {!!customers?.length && (
                <>
                  <Table
                    route={'customers'}
                    loading={false}
                    hiddenFields={['id']}
                    titles={CustomersTitles}
                    rows={
                      customers?.map((customer) => ({
                        id: customer.customer.id,
                        name: (
                          <p>
                            {customer.customer.name
                              ? customer.customer.name
                              : '-'}
                          </p>
                        ),
                        email: <p>{customer.customer.email}</p>,
                        phoneNumber: (
                          <p>
                            {customer.customer.phoneNumber
                              ? customer.customer.phoneNumber
                              : '-'}
                          </p>
                        ),
                        createdAt: (
                          <p>
                            {moment(customer.createdAt).format(
                              `DD/MM/YYYY HH:mm`
                            )}
                          </p>
                        ),
                      })) || []
                    }
                  />
                  {!!pagesCount && (
                    <div className='page_in_pagination'>
                      <Pagination
                        pagesCount={pagesCount}
                        setOffset={setOffset}
                        limit={limit}
                        limitPlaceholder={limit.label}
                        limits={LIMITS}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onChange={onChangeLimit}
                      />
                    </div>
                  )}
                </>
              )}
              {!customers?.length && <NoData title={t('noData.customers')} />}
            </div>
          )}
          {loading && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Customers);
