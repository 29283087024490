import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import Loading from '../../../components/loading';
import api from '../../../utils/shop-api';
import { errToString } from '../../../utils';
import cn from 'classnames';
import Logo from '../../../assets/logo/QRrobo transparent.png';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import UserContext from '../../../context/user/user-context';

function ShopPassCode({ tableId, setIsAuth }) {
  const { setUser } = useContext(UserContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const branchId = pathname.split('/')[2];
  const [hasTablePasscode, setHasTablePasscode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState(['', '', '', '']);

  const onCallWaiter = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      api
        .post(`/shop/${branchId}/call`, {
          tableId,
        })
        .then(() => {
          const audio = new Audio(sound);
          const info = 'Waiter was successfully called!';
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
        })
        .catch((err) => console.log(t(`errors.${errToString(err)}`)));
    },
    [t, branchId, tableId]
  );

  const handleKeyDown = (e, index) => {
    if (e.key === 'ArrowLeft' && index > 0) {
      const prevInput = formRef.current.querySelector(
        `input:nth-child(${index})`
      );
      prevInput && prevInput.focus();
    } else if (e.key === 'ArrowRight' && index < code.length - 1) {
      const nextInput = formRef.current.querySelector(
        `input:nth-child(${index + 2})`
      );
      nextInput && nextInput.focus();
    } else if (e.key === 'Backspace') {
      e.preventDefault();
      setError(null);
      setCode(['', '', '', '']);
      const firstInput = formRef.current.querySelector('input:first-child');
      firstInput && firstInput.focus();
    }
  };
  const onChange = (index, value) => {
    setError(null);
    if (
      value !== '0' &&
      value !== '1' &&
      value !== '2' &&
      value !== '3' &&
      value !== '4' &&
      value !== '5' &&
      value !== '6' &&
      value !== '7' &&
      value !== '8' &&
      value !== '9'
    ) {
      return;
    }

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    const nextIndex = index + 1;
    if (nextIndex < code.length) {
      const nextInput = formRef.current.querySelector(
        `input:nth-child(${nextIndex + 1})`
      );
      nextInput && nextInput.focus();
    }
  };

  const onSubmit = useCallback(() => {
    if (code[0] === '' || code[1] === '' || code[2] === '' || code[3] === '') {
      return;
    }

    const data = {
      passcode: `${code[0]}${code[1]}${code[2]}${code[3]}`,
      branchId: branchId,
    };

    setLoading(true);

    if (!hasTablePasscode) {
      api
        .post(`/guests/tables/${tableId}/passcode`, data)
        .then(() => {
          setError(null);

          api
            .get('/guests/token')
            .then(({ data }) => {
              setUser(data);
              setIsAuth(true);
              setLoading(false);
            })
            .catch(() => {
              //TODO error
              setError('Oops, something went wrong!');
              setIsAuth(false);
            });
        })
        .catch((err) => {
          setLoading(false);
          //TODO error
          return setError(t(`errors.${errToString(err)}`));
        });
    }
    if (hasTablePasscode) {
      api
        .post(`/guests/tables/${tableId}/passcode/verify`, data)
        .then(() => {
          setError(null);

          api
            .get('/guests/token')
            .then(({ data }) => {
              setUser(data);
              setIsAuth(true);
              setLoading(false);
            })
            .catch(() => {
              //TODO error
              setError('Oops, something went wrong!');
              setIsAuth(false);
            });
        })
        .catch(() => {
          //TODO error
          setLoading(false);
          return setError('Passcode is wrong');
        });
    }
  }, [code, branchId, hasTablePasscode, tableId, setUser, setIsAuth, t]);

  useEffect(() => {
    api
      .get(`/guests/tables/${tableId}/passcode`)
      .then((res) => {
        return setHasTablePasscode(res.data.hasTablePasscode);
      })
      .catch((err) => console.log(t(`errors.${errToString(err)}`)));
  }, [t, tableId]);
  useEffect(() => {
    if (code[0] !== '' && code[1] !== '' && code[2] !== '' && code[3] !== '') {
      return onSubmit();
    }
  }, [code, onSubmit]);
  useEffect(() => {
    const firstInput = formRef.current.querySelector('input:first-child');
    firstInput && firstInput.focus();
  }, []);

  return (
    <div className='shop_sign_up'>
      <div
        className={cn('shop_sign_up_header', {
          'shop_sign_up_header--dark': true,
        })}
      >
        <img src={Logo} alt='qrrobo' />
      </div>
      <div className={cn('shop_sign_up_form shop_sign_up_form--active')}>
        <p className='shop_sign_up_form_title'>Welcome</p>
        <p className='shop_sign_up_form_description'>Enter table passcode</p>
        <div className='shop_sign_up_form_in'>
          <form
            ref={formRef}
            onSubmit={onSubmit}
            className='shop_passcode_form'
          >
            <div className='shop_passcode_form_in'>
              {!loading &&
                code.map((value, index) => (
                  <input
                    key={index}
                    type='tel'
                    maxLength='1'
                    pattern='[0-9]'
                    className='shop_passcode_form_control'
                    value={value}
                    onChange={(e) => onChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              {loading && (
                <div className='shop_passcode_form_in shop_passcode_form_in_loading'>
                  <Loading />
                </div>
              )}
            </div>
            <div className='shop_passcode_form_call'>
              <p>Are you having a trouble?</p>
              <button onClick={onCallWaiter}>Call waiter</button>
            </div>
            <div className='shop_passcode_form_error'>
              {error && (
                <div className='shop_passcode_form_error_name'>
                  <PiWarningOctagonDuotone />
                  <p>{error}</p>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className='shop_sign_up_form_footer'>
          By continuing, you automatically accept our Terms & Conditions,
          Privacy Policy and Cookies policy.
        </div>
        <div className='shop_passcode_form_footer'>
          <p>
            Powered by{' '}
            <a href='https://qrrobo.com/' target='_blank' rel='noreferrer'>
              qrrobo.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default memo(ShopPassCode);
