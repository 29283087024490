import './index.scss';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import api from '../../../../utils/shop-api';
import Loading from '../../../../components/loading';
import ShopCategoryProduct from './shop-category-product';
import ShopBasketButton from '../../shop-basket/shop-basket-button';
import ShopCheckoutButton from '../../shop-checkout/shop-checkout-button';
import { useLocation, useNavigate } from 'react-router-dom';
import { errToString } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import PushNotifications from '../../../../components/push-notifications';
import cn from 'classnames';
import UserContext from '../../../../context/user/user-context';
import MenuContext from '../../../../context/menu/menu-context';

function ShopCategories({ tableId, branchId, categories, lngId }) {
  const { hash } = useLocation();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { menu } = useContext(MenuContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const menuRef = useRef(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(() => {
    if (!hash) {
      if (user.customer) {
        return 'category-id-for-you';
      } else {
        return 'no-user';
      }
    } else if (hash === '#category-id-for-you') {
      return 'category-id-for-you';
    } else if (hash === '#category-id-liked') {
      return 'category-id-liked';
    } else if (hash.startsWith('#category-id-')) {
      return Number(hash.replace('#category-id-', ''));
    }
  });
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState(
    localStorage.getItem('selectedCategoryTitle') || categories[0]?.name
  );
  const [loading, setLoading] = useState(true);
  const [grow, setGrow] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleItemClick = (category) => {
    setSelectedCategoryId(category.id);
    setSelectedCategoryTitle(category.name);
    localStorage.setItem('selectedCategoryTitle', category.name);
    navigate(`/shop/${branchId}/tables/${tableId}/#category-id-${category.id}`);
  };

  useEffect(() => {
    setLoading(true);

    if (!selectedCategoryId) {
      return;
    }

    if (selectedCategoryId === 'category-id-for-you') {
      api
        .get(
          `/shop/${branchId}/products/for-you?languageId=${lngId}&offset=0&limit=100`
        )
        .then((res) => {
          setLoading(false);
          setProducts(res.data.results.filter(({ deletedAt }) => !deletedAt));
        })
        .catch((err) => {
          setLoading(false);
          console.log(errToString(err));
        });
    }
    if (selectedCategoryId === 'category-id-liked') {
      api
        .get(
          `/shop/${branchId}/product/like?languageId=${lngId}&offset=0&limit=100`
        )
        .then((res) => {
          setLoading(false);
          const arr = res.data.likes.results.map((e) => e.product);
          setProducts(arr.filter(({ deletedAt }) => !deletedAt));
        })
        .catch((err) => {
          setLoading(false);
          console.log(errToString(err));
        });
    }
    if (
      selectedCategoryId !== 'category-id-for-you' &&
      selectedCategoryId !== 'category-id-liked'
    ) {
      api
        .get(
          `/shop/${branchId}/products?categoryId=${selectedCategoryId}&languageId=${lngId}&offset=0&limit=1000`
        )
        .then((res) => {
          setLoading(false);
          setProducts(res.data.results.filter(({ deletedAt }) => !deletedAt));
        })
        .catch((err) => {
          setLoading(false);
          console.log(errToString(err));
        });
    }
  }, [branchId, selectedCategoryId, lngId]);
  useEffect(() => {
    if (hash) {
      document.querySelector(hash)?.scrollIntoView();
    }
  }, [hash]);
  useEffect(() => {
    if (menuRef.current) {
      const selectedElement = menuRef.current.querySelector(
        `#category-id-${selectedCategoryId}`
      );

      if (selectedElement) {
        const offsetLeft = selectedElement.offsetLeft;

        menuRef.current.scrollLeft = offsetLeft;
        selectedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
        const scrollOptions = {
          left: offsetLeft,
          behavior: 'smooth',
        };

        menuRef.current.scrollTo(scrollOptions);
      }
    }
  }, [selectedCategoryId]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setSelectedCategoryId(() => {
      if (!hash) {
        if (user.customer) {
          setSelectedCategoryTitle(t('general.forYou'));
          return 'category-id-for-you';
        } else {
          setSelectedCategoryTitle(categories[0]?.name);
          return categories[0]?.id;
        }
      } else if (hash === '#category-id-for-you') {
        setSelectedCategoryTitle(t('general.forYou'));
        return 'category-id-for-you';
      } else if (hash === '#category-id-liked') {
        setSelectedCategoryTitle(t('general.likedProducts'));
        return 'category-id-liked';
      } else if (hash.startsWith('#category-id-')) {
        setSelectedCategoryTitle(localStorage.getItem('selectedCategoryTitle'));
        return Number(hash.replace('#category-id-', ''));
      }
    });
  }, [t, hash, categories, user]);

  console.log('menu', menu);

  return (
    <div className={cn('shop_categories')}>
      {categories && !!categories?.length && (
        <>
          <div
            ref={menuRef}
            className={cn('shop_categories_scrollmenu', {
              'shop_categories_scrollmenu--dark': false,
            })}
            style={{
              display: 'flex',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
            }}
          >
            {user?.customer && (
              <>
                <a
                  id={`category-id-for-you`}
                  href='!#'
                  className={`shop_categories_scrollmenu_item ${selectedCategoryId === 'category-id-for-you' ? 'shop_categories_scrollmenu_item--active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedCategoryId('category-id-for-you');
                    setSelectedCategoryTitle(`${t('general.forYou')}`);
                    localStorage.setItem(
                      'selectedCategoryTitle',
                      `${t('general.forYou')}`
                    );
                    navigate(
                      `/shop/${branchId}/tables/${tableId}/#category-id-for-you`
                    );
                  }}
                >
                  {t('general.forYou')}
                </a>
                <a
                  id={`category-id-liked`}
                  href='!#'
                  className={`shop_categories_scrollmenu_item ${selectedCategoryId === 'category-id-liked' ? 'shop_categories_scrollmenu_item--active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedCategoryId('category-id-liked');
                    setSelectedCategoryTitle(`${t('general.likedProducts')}`);
                    localStorage.setItem(
                      'selectedCategoryTitle',
                      `${t('general.likedProducts')}`
                    );
                    navigate(
                      `/shop/${branchId}/tables/${tableId}/#category-id-liked`
                    );
                  }}
                >
                  {t('general.likedProducts')}
                </a>
              </>
            )}
            {categories.map((category) => (
              <a
                key={category.id}
                id={`category-id-${category.id}`}
                href='!#'
                className={`shop_categories_scrollmenu_item ${selectedCategoryId === category.id ? 'shop_categories_scrollmenu_item--active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleItemClick(category);
                }}
              >
                {category.name}
              </a>
            ))}
          </div>
          <div
            className={cn('shop_categories_title', {
              'shop_categories_title--dark': false,
            })}
          >
            {!loading && <p>{selectedCategoryTitle}</p>}
            {loading && (
              <>
                <p>{selectedCategoryTitle}</p>
                <span>
                  <Loading />
                </span>
              </>
            )}
          </div>
        </>
      )}
      {!loading && !!categories?.length && !!products?.length && (
        <div
          className={cn('shop_categories_products', {
            'shop_categories_products--dark': false,
          })}
        >
          <ShopCategoryProduct
            products={products}
            showDetails={true}
            setGrow={setGrow}
            setShrink={setShrink}
            isDarkMode={false}
          />
          <div
            className={cn('shop_categories_footer', {
              'shop_categories_footer--short': windowWidth > 900,
            })}
          >
            {windowWidth <= 900 && (
              <ShopBasketButton
                branchId={branchId}
                grow={grow}
                setGrow={setGrow}
                shrink={shrink}
                setShrink={setShrink}
                isDarkMode={false}
                lngId={lngId}
              />
            )}
            <ShopCheckoutButton
              lngId={lngId}
              tableId={tableId}
              branchId={branchId}
              isDarkMode={false}
            />
          </div>
        </div>
      )}
      {!loading && categories && !categories.length && (
        <div className='shop_header_in_search_modal_in'>
          <div className='shop_header_in_search_modal_in_loading'>
            <h2 className='shop_header_in_search_modal_in_title'>
              {t('shop.noCategories')}
            </h2>
            <p className='shop_header_in_search_modal_in_description'>
              {t('shop.noCategoriesDescrip')}
            </p>
          </div>
        </div>
      )}
      {!loading && !!categories?.length && !products?.length && (
        <div className='shop_header_in_search_modal_in'>
          <div className='shop_header_in_search_modal_in_loading'>
            <h2 className='shop_header_in_search_modal_in_title'>
              {t('shop.noProducts')}
            </h2>
            <p className='shop_header_in_search_modal_in_description'>
              {t('shop.noProductsDescrip')}
            </p>
          </div>
        </div>
      )}
      <PushNotifications />
    </div>
  );
}

export default memo(ShopCategories);
