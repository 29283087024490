import { BsFillBasket3Fill } from 'react-icons/bs';
import { BsFillMenuButtonFill } from 'react-icons/bs';
import { AiFillShop } from 'react-icons/ai';
import { FaUserFriends } from 'react-icons/fa';
import { MdIntegrationInstructions, MdTableChart } from 'react-icons/md';
import { MdOutlinePayment } from 'react-icons/md';
import { FaCodeBranch } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import { BsFillPeopleFill } from 'react-icons/bs';
import { RiReservedFill } from 'react-icons/ri';

export const NAVIGATION_ROUTES = [
  {
    id: 'live_orders',
    links: ['/'],
    icon: <BsFillBasket3Fill />,
    color: '#999',
  },
  {
    id: 'orders',
    links: ['/orders'],
    icon: <BsFillBasket3Fill />,
    color: '#999',
  },
  {
    id: 'customers',
    links: ['/customers'],
    icon: <FaUserFriends />,
    color: '#999',
  },
  {
    id: 'reservations',
    links: ['/reservations'],
    icon: <RiReservedFill />,
    color: '#999',
  },
  {
    id: 'staff',
    links: ['/staff'],
    icon: <BsFillPeopleFill />,
    color: '#999',
  },
  {
    id: 'menu',
    links: ['/menu'],
    icon: <BsFillMenuButtonFill />,
    color: '#999',
  },
  { id: 'tables', links: ['/tables'], icon: <MdTableChart />, color: '#999' },
  {
    id: 'branches',
    links: ['/branches'],
    icon: <FaCodeBranch />,
    color: '#999',
  },
  {
    id: 'settings',
    links: ['/settings'],
    icon: <AiFillShop />,
    color: '#999',
  },
  { id: 'reviews', links: ['/reviews'], icon: <FaStar />, color: '#999' },
  {
    id: 'integrations',
    links: ['/integrations'],
    icon: <MdIntegrationInstructions />,
    color: '#999',
  },
  {
    id: 'paymentMethods',
    links: ['/payment-methods'],
    icon: <MdOutlinePayment />,
    color: '#999',
  },
];
