import './index.scss';
import React, { memo, useCallback, useContext, useState } from 'react';
import Gmail from '../../../../assets/icons/gmail.svg';
import Email from '../../../../assets/icons/email.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { FaArrowCircleRight } from 'react-icons/fa';
import api from '../../../../utils/shop-api';
import { errToString } from '../../../../utils';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import UserContext from '../../../../context/user/user-context';
import PreferencesContext from '../../../../context/preferences/preferences-context';
import Loading from '../../../../components/loading';
import parsePhoneNumberFromString from 'libphonenumber-js';

function ShopSignUp({ setShow, invoiceId }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setUser } = useContext(UserContext);
  const { list } = useContext(PreferencesContext);

  const [isOtherEmailActive, setIsOtherEmailActive] = useState(false);
  const [otherEmailPage, setOtherEmailPage] = useState('email');
  const [otherEmail, setOtherEmail] = useState('');
  const [otherEmailName, setOtherEmailName] = useState('');
  const [otherEmailCode, setOtherEmailCode] = useState('');
  const tableId = pathname.split('/')[4];
  const [showPhoneNumberForm, setShowPhoneNumberForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [googleAccessToken, setGoogleAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const formatPhoneNumber = (input) => {
    const parsedNumber = parsePhoneNumberFromString(input, 'AM');
    if (parsedNumber) {
      return parsedNumber.formatInternational();
    }
    return input;
  };

  const handlePhoneChange = (e) => {
    const rawInput = e.target.value;
    const formattedNumber = formatPhoneNumber(rawInput);
    setPhoneNumber(formattedNumber);
  };

  const onGmailSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGoogleAccessToken(tokenResponse.access_token);
      return setShowPhoneNumberForm(true);
    },
    onError: (err) => {
      alert(err);
      console.log(err);
    },
    onNonOAuthError: (err) => {
      alert(err);
      console.log(err);
    },
  });
  const onPhoneNumberVerifyGoogle = useCallback(() => {
    const data = {
      accessToken: googleAccessToken,
      tableId: tableId,
      invoiceId: invoiceId,
      phoneNumber: phoneNumber,
    };

    setLoading(true);

    api
      .post('/guests/google', data)
      .then(({ data }) => {
        setUser(data.guest);
        setShowPhoneNumberForm(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err));
        //TODO error
        alert(errToString(err));
      });
  }, [googleAccessToken, invoiceId, setUser, tableId, phoneNumber]);
  const onChangeOtherEmailPage = useCallback(() => {
    if (otherEmailPage === 'email') {
      setOtherEmailPage('email');
      setOtherEmail('');
      setOtherEmailName('');
      setOtherEmailCode('');
      return setIsOtherEmailActive(false);
    }
    if (otherEmailPage === 'name') {
      return setOtherEmailPage('email');
    }
    if (otherEmailPage === 'phone') {
      return setOtherEmailPage('name');
    }
    if (otherEmailPage === 'code') {
      return setOtherEmailPage('name');
    }
  }, [otherEmailPage]);
  const onEmailSignUp = useCallback(() => {
    const data = {
      name: otherEmailName,
      email: otherEmail,
      tableId: tableId,
      invoiceId: invoiceId,
      phoneNumber: phoneNumber,
    };
    setLoading(true);

    api
      .post('/guests/email', data)
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
        setOtherEmailPage('code');
        const audio = new Audio(sound);
        const info = `Verification code has just been send to ${otherEmail}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
      })
      .catch((err) => {
        setOtherEmailPage('email');
        setLoading(false);
        console.log(errToString(err));
        //TODO error
        alert(errToString(err));
      });
  }, [tableId, otherEmail, otherEmailName, invoiceId, phoneNumber]);
  const onEmailSignUpVerify = useCallback(() => {
    const data = {
      code: otherEmailCode,
    };

    setLoading(true);

    api
      .post('/guests/email/verify', data)
      .then(({ data }) => {
        setUser(data.guest);
        setLoading(false);
        setIsOtherEmailActive(false);
        setOtherEmail('');
        setOtherEmailName('');
        setOtherEmailCode('');
        setShow(false);
      })
      .catch((err) => {
        //TODO error

        setOtherEmailCode('');
        setLoading(false);
        const audio = new Audio(sound);
        const info = `Something went wrong, please try again`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        console.log(errToString(err));
        alert(errToString(err));
      });
  }, [setUser, otherEmailCode, setShow]);

  return (
    <div className='shop_sign_up'>
      <div
        className={cn('shop_sign_up_header', {
          'shop_sign_up_header--dark': false,
        })}
      >
        <img src={list?.src} alt='qrrobo' />
        {!isOtherEmailActive && !showPhoneNumberForm && (
          <button onClick={() => setShow(false)}>{t('general.skip')}</button>
        )}
      </div>
      <div
        className={cn('shop_sign_up_form shop_sign_up_form--active', {
          'shop_sign_up_form--dark': false,
        })}
      >
        {!showPhoneNumberForm && !isOtherEmailActive && (
          <>
            <p className='shop_sign_up_form_title'>{t('general.welcome')}</p>
            <p className='shop_sign_up_form_description'>
              {t('general.letsStartSigning')}
            </p>
          </>
        )}
        {showPhoneNumberForm && (
          <>
            <p className='shop_sign_up_form_title'>Verification</p>
            <p className='shop_sign_up_form_description'>
              Enter your phone number to verify your identity.
            </p>
          </>
        )}
        {isOtherEmailActive && (
          <>
            <p className='shop_sign_up_form_title'>Verification</p>
            <p className='shop_sign_up_form_description'>
              {otherEmailPage === 'email' && `Please enter your email`}
              {otherEmailPage === 'name' && `Please enter your name`}
              {otherEmailPage === 'phone' && `Please enter your phone number`}
              {otherEmailPage === 'code' &&
                `Verification code was sent to your email. Enter the code to verify your identity.`}
            </p>
          </>
        )}
        {!showPhoneNumberForm && !loading && (
          <div className='shop_sign_up_form_in'>
            {!isOtherEmailActive && (
              <>
                <div
                  className='shop_sign_up_form_in_btn'
                  onClick={() => onGmailSignUp()}
                >
                  <img src={Gmail} alt='gmail' />
                  Google
                </div>
                <div
                  className='shop_sign_up_form_in_btn'
                  onClick={() => setIsOtherEmailActive(true)}
                >
                  <img src={Email} alt='email' />
                  Email
                </div>
              </>
            )}
            {isOtherEmailActive && (
              <>
                {otherEmailPage === 'email' && (
                  <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                    <input
                      className='shop_sign_up_form_in_btn_input'
                      type='text'
                      value={otherEmail}
                      placeholder={t('general.email')}
                      onChange={(e) => setOtherEmail(e.target.value)}
                    />
                    <div
                      className='shop_sign_up_form_in_btn_icon'
                      onClick={() => setOtherEmailPage('name')}
                    >
                      <FaArrowCircleRight />
                    </div>
                  </div>
                )}
                {otherEmailPage === 'name' && (
                  <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                    <input
                      className='shop_sign_up_form_in_btn_input'
                      type='text'
                      value={otherEmailName}
                      placeholder={t('general.name')}
                      onChange={(e) => setOtherEmailName(e.target.value)}
                    />
                    <div
                      className='shop_sign_up_form_in_btn_icon'
                      onClick={() => setOtherEmailPage('phone')}
                    >
                      <FaArrowCircleRight />
                    </div>
                  </div>
                )}
                {otherEmailPage === 'phone' && (
                  <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                    <input
                      className='shop_sign_up_form_in_btn_input'
                      type='text'
                      value={phoneNumber}
                      placeholder={t('general.phoneNumber')}
                      onChange={handlePhoneChange}
                    />
                    <div
                      className='shop_sign_up_form_in_btn_icon'
                      onClick={onEmailSignUp}
                    >
                      <FaArrowCircleRight />
                    </div>
                  </div>
                )}
                {otherEmailPage === 'code' && (
                  <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                    <input
                      className='shop_sign_up_form_in_btn_input'
                      type='text'
                      value={otherEmailCode}
                      placeholder={t('general.code')}
                      onChange={(e) => setOtherEmailCode(e.target.value)}
                    />
                    <div
                      className='shop_sign_up_form_in_btn_icon'
                      onClick={onEmailSignUpVerify}
                    >
                      <FaArrowCircleRight />
                    </div>
                  </div>
                )}
                <div
                  className='shop_sign_up_form_in_btn_back'
                  onClick={onChangeOtherEmailPage}
                >
                  {t('general.back')}
                </div>
              </>
            )}
          </div>
        )}
        {showPhoneNumberForm && !loading && (
          <div className='shop_sign_up_form_in'>
            <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
              <input
                className='shop_sign_up_form_in_btn_input'
                type='text'
                value={phoneNumber}
                placeholder={t('general.phoneNumber')}
                onChange={handlePhoneChange}
              />
              <div
                className='shop_sign_up_form_in_btn_icon'
                onClick={onPhoneNumberVerifyGoogle}
              >
                <FaArrowCircleRight />
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className='shop_sign_up_form_in'>
            <Loading />
          </div>
        )}
        <div className='shop_sign_up_form_footer'>{t('general.shopTerms')}</div>
      </div>
    </div>
  );
}

export default memo(ShopSignUp);
