import './index.scss';
import React, { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import api from '../../../../../../../utils/api';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function AddProductExtra({
  setSelectedProduct,
  selectedProduct,
  setAddProductExtra,
  lng,
  lngId,
  onRefresh,
  setSelectedExtra,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [error, setError] = useState(null);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setError(null);

      const data = {
        name,
        price,
        branchId: selectedProduct.branchId,
      };

      api
        .post(
          `/products/${selectedProduct.id}/options?languageId=${lngId}`,
          data
        )
        .then(({ data }) => {
          setAddProductExtra(false);
          setSelectedProduct({
            ...selectedProduct,
            options: [...selectedProduct.options, data],
          });
          onRefresh();
          setSelectedExtra(null);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          //TODO error
        });
    },
    [
      setSelectedExtra,
      t,
      onRefresh,
      lngId,
      name,
      price,
      selectedProduct,
      setSelectedProduct,
      setAddProductExtra,
    ]
  );

  return createPortal(
    <div className='add_product_extra' onClick={(e) => e.stopPropagation()}>
      <div className='add_product_extra_in add_product_extra_in--active'>
        <div className='add_product_extra_in_header'>
          <div className='add_product_extra_in_header_title'>
            <h2>
              {t('general.editExtra')} {lng?.toUpperCase()}
            </h2>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${lng?.toLowerCase()}.svg`}
              width='48'
              alt={lng}
            />
          </div>
          <i
            onClick={(e) => {
              e.stopPropagation();
              setAddProductExtra(false);
              setSelectedExtra(null);
            }}
          >
            <IoMdClose />
          </i>
        </div>
        <form className='form' onSubmit={onSubmit}>
          <div className='form_columns'>
            <div className='form_column'>
              <label htmlFor=''>{t('dashboard.menu.name')}</label>
              <input
                type='text'
                value={name}
                placeholder={t('dashboard.menu.name')}
                onChange={(e) => onChangeField(e, setName)}
              />
            </div>
          </div>
          <div className='form_columns'>
            <div className='form_column'>
              <label htmlFor=''>{t('dashboard.menu.price')}</label>
              <input
                type='text'
                value={price}
                placeholder={t('dashboard.menu.price')}
                onChange={(e) => onChangeField(e, setPrice)}
              />
            </div>
          </div>
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
              {t('general.save')}
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

AddProductExtra.propTypes = {
  setAddProductExtra: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,
  lngId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default memo(AddProductExtra);
