import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import NoData from '../../../../components/no-data';
import Loading from '../../../../components/loading';
import { useTranslation } from 'react-i18next';
import { RiSearchLine } from 'react-icons/ri';
import { useDebouncedCallback } from 'use-debounce';
import api from '../../../../utils/api';
import { errToString } from '../../../../utils';
import cn from 'classnames';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function TerminalTable({
  tablesLoading,
  tables,
  setSelectedTable,
  setTablesLoading,
  setTables,
  selectedBranch,
  invoices,
  setCheckForPos,
  checkForPos,
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const invoiceTableIds = useMemo(
    () => new Set(invoices.map((invoice) => invoice.table.id)),
    [invoices]
  );

  // Filter tables to exclude those that are in the invoices
  const filteredTables = useMemo(
    () => tables?.filter((table) => !invoiceTableIds.has(table.id)),
    [tables, invoiceTableIds]
  );
  const onSearch = useDebouncedCallback((search) => {
    if (!selectedBranch) {
      return;
    }

    api
      .get(
        `/tables?offset=0&limit=1000&branchId=${selectedBranch.id}${search ? `&search=${search}` : ''}`
      )
      .then((res) => {
        setTablesLoading(false);
        return setTables(
          res.data.results.filter(({ deletedAt }) => !deletedAt)
        );
      })
      .catch((err) => {
        //TODO error
        setTablesLoading(false);
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, 300);
  const checkPos = useCallback(
    (table) => {
      api
        .post(`/tables/syncPos`, {
          tableId: table.id,
        })
        .then(() => {
          setCheckForPos(true);
        })
        .catch((err) => {
          console.log(err);
          setCheckForPos(true);
        });
    },
    [setCheckForPos]
  );

  useEffect(() => {
    setTablesLoading(true);

    onSearch(search);
  }, [search, onSearch, setTablesLoading]);

  return (
    <div className='terminal_in_body'>
      <div className='terminal_in_body_tables'>
        <div className='terminal_in_body_tables_search'>
          <div className='terminal_in_body_tables_search_icon'>
            <RiSearchLine />
          </div>
          <input
            type='text'
            placeholder={t('general.searchTable')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {!tablesLoading && !!tables?.length && (
          <div className='terminal_in_body_tables_in'>
            {filteredTables.map((table) => (
              <div
                key={table.id}
                className={cn('terminal_in_body_tables_in_item', {
                  'terminal_in_body_tables_in_item--busy': table.isActive,
                })}
                onClick={() => {
                  setSelectedTable(table);
                  return checkPos(table);
                }}
              >
                <p>{table?.name}</p>
                <p>{table?.area.name}</p>
              </div>
            ))}
            {invoices.map((invoice) => (
              <div
                key={invoice.table.id}
                className={cn('terminal_in_body_tables_in_item', {
                  'terminal_in_body_tables_in_item--busy':
                    !!invoice.orders.length,
                })}
                onClick={() => {
                  setSelectedTable(invoice.table);
                  setCheckForPos(true);
                }}
              >
                <p>{invoice.table?.name}</p>
                <p>{invoice.table?.area.name}</p>
              </div>
            ))}
          </div>
        )}
        {!tablesLoading && !tables?.length && (
          <NoData title={t('noData.tables')} />
        )}
        {tablesLoading && (
          <div className='terminal_in_body_loading'>
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(TerminalTable);
