import { useReducer, useCallback, memo } from 'react';
import MenuContext from './menu-context';

const defaultMenuState = {
  menu: [],
  set: () => {},
};

const menuReducer = (state, action) => {
  if (action.type === 'SET_MENU') {
    return {
      ...state,
      ...action.payload,
    };
  }

  return defaultMenuState;
};

const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(menuReducer, defaultMenuState);

  const setMenu = useCallback(
    (menu) => {
      dispatch({
        type: 'SET_MENU',
        payload: { menu },
      });
    },
    [dispatch]
  );

  const menuContext = {
    menu: state.menu,
    set: setMenu,
  };

  return (
    <MenuContext.Provider value={menuContext}>{children}</MenuContext.Provider>
  );
};

export default memo(MenuProvider);
