import './index.scss';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import ShopContext from '../../../../context/shop/shop-context';
import ShopBasket from '../index';
import cn from 'classnames';
import { toPriceWithCurrency } from '../../../../utils';
import PreferencesContext from '../../../../context/preferences/preferences-context';
import { TbMoodEmpty } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import Overlay from '../../../../components/layout/overlay';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function ShopBasketButton({
  branchId,
  grow,
  shrink,
  setGrow,
  setShrink,
  lngId,
}) {
  const { products: basketProducts } = useContext(ShopContext);
  const { list } = useContext(PreferencesContext);
  const { t } = useTranslation();

  const [basketIsOpen, setBasketIsOpen] = useState(false);
  const totalCount = useMemo(
    () => basketProducts.reduce((acc, val) => acc + val.qty, 0),
    [basketProducts]
  );
  const totalAmount = useMemo(
    () =>
      basketProducts.reduce((acc, val) => {
        return (
          acc +
          val.price * val.qty +
          val.options.reduce((acc, val) => acc + val.price, 0) * val.qty
        );
      }, 0),
    [basketProducts]
  );

  const onBasketOpen = useCallback(() => {
    if (totalCount !== 0) {
      return setBasketIsOpen(true);
    }
    if (totalCount === 0) {
      const audio = new Audio(sound);
      const info = t('shop.basketEmpty');
      toast.info(info, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      return audio.play();
    }
  }, [t, totalCount]);

  return (
    <>
      <div
        className={cn(
          'shop_basket_btn',
          { 'shop_basket_btn--grow': grow },
          { 'shop_basket_btn--shrink': shrink }
        )}
        onAnimationEnd={() => {
          setShrink(false);
          setGrow(false);
        }}
        onClick={onBasketOpen}
      >
        <div
          className={cn(
            'shop_basket_btn_in',
            { 'shop_basket_btn_in--dark': false },
            { 'shop_basket_btn_in--empty': totalCount === 0 },
            { 'shop_basket_btn_in--active': totalCount !== 0 }
          )}
        >
          {totalCount === 0 && <TbMoodEmpty />}
          {totalCount !== 0 && (
            <>
              <span className='shop_basket_btn_in_count'>{totalCount}</span>
              <span className='shop_basket_btn_in_name'>
                {t('shop.basket')}
              </span>
              <span className='shop_basket_btn_in_amount'>{`${toPriceWithCurrency(Number(totalAmount), list.restaurantInfo.currency)}`}</span>
            </>
          )}
          {totalCount === 0 && <p>{t('shop.basketEmpty')}</p>}
        </div>
      </div>
      {basketIsOpen && (
        <>
          <ShopBasket
            branchId={branchId}
            products={basketProducts}
            setBasketIsOpen={setBasketIsOpen}
            totalCount={totalCount}
            totalAmount={totalAmount}
            basketIsOpen={basketIsOpen}
            lngId={lngId}
            isDarkMode={false}
          />
          <Overlay
            isTransparent={true}
            onClick={() => setBasketIsOpen(false)}
          />
        </>
      )}
    </>
  );
}

export default memo(ShopBasketButton);
