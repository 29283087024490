import React, { memo, useCallback, useState } from 'react';
import './index.scss';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import LanguageDropDown from '../../../../components/language-drop-down';
import api from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import Loading from '../../../../components/loading';
import cn from 'classnames';

function AddLanguage({
  setShowCreateLng,
  primary = false,
  setCreatedLanguages,
  type = 'default',
  setLngId = () => {},
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  const onCreateLanguage = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setPending(true);

      if (type === 'default') {
        api
          .post('restaurant/languages', {
            name: selected?.toLowerCase(),
          })
          .then((res) => {
            setShowCreateLng(false);
            setPending(false);
            return setCreatedLanguages(res.data);
          })
          .catch((err) => {
            //TODO error
            setError(t(`errors.${errToString(err)}`));
            setPending(false);
          });
      }
      if (type === 'live-orders') {
        api
          .post('restaurant/languages', {
            name: selected?.toLowerCase(),
          })
          .then((res) => {
            setPending(false);
            setShowCreateLng('show');
            return setLngId(res.data[0].id);
          })
          .catch((err) => {
            //TODO error
            setError(t(`errors.${errToString(err)}`));
            setPending(false);
          });
      }
    },
    [type, t, selected, setCreatedLanguages, setShowCreateLng, setLngId]
  );

  return createPortal(
    <div
      className={cn('add_language', {
        'add_language--wrapper': type !== 'default',
      })}
    >
      <div className='add_language_in add_language_in--active'>
        <div className='add_language_in_header'>
          <h2>
            {!primary ? t('general.createLng') : t('general.createPrimaryLng')}
          </h2>
          {type === 'default' && (
            <i
              onClick={() => {
                if (primary) {
                  return navigate('/');
                } else {
                  return setShowCreateLng(false);
                }
              }}
            >
              <IoMdClose />
            </i>
          )}
        </div>
        <form className='form' onSubmit={onCreateLanguage}>
          <div className='form_columns'>
            <div className='form_column'>
              <LanguageDropDown
                selected={selected}
                setSelected={setSelected}
                searchable={true}
                className={'lng_drop_down'}
                countries={['AM', 'RU', 'US', 'ES', 'FR', 'DE']}
                customLabels={{
                  AM: 'Armenian',
                  RU: 'Russian',
                  US: 'English',
                  ES: 'Spanish',
                  FR: 'French',
                  DE: 'German',
                }}
              />
            </div>
          </div>
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            {!pending && (
              <button className='form_actions_btn' type={'submit'}>
                {t('general.save')}
              </button>
            )}
            {pending && (
              <button className='form_actions_btn form_actions_btn--loading'>
                <Loading />
              </button>
            )}
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

export default memo(AddLanguage);
