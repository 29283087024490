import './index.scss';
import { memo, useContext, useEffect, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { FaWifi } from 'react-icons/fa';
import { useDebouncedCallback } from 'use-debounce';
import api from '../../../../utils/shop-api';
import { BiSolidUser } from 'react-icons/bi';
import { errToString } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import ShopHeaderLng from './shop-header-lng';
import ShopHeaderWiFi from './shop-header-wifi';
import ShopHeaderProfile from './shop-header-profile';
import ShopHeaderSearch from './shop-header-search';
import { BiSolidBellRing } from 'react-icons/bi';
import cn from 'classnames';
import PreferencesContext from '../../../../context/preferences/preferences-context';
import Loading from '../../../../components/loading';

function ShopHeader({
  branchId,
  tableId,
  lng,
  lngId,
  setLng,
  setLngId,
  setShowLanguageDropDown,
  showLanguageDropDown,
  languages,
  setShowSignUpPage,
  showSignUpPage,
}) {
  const { t } = useTranslation();
  const { list } = useContext(PreferencesContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showWiFiOpen, setShowWiFiOpen] = useState(false);

  const onSearch = useDebouncedCallback((search) => {
    if (!search) {
      return;
    }

    api
      .get(`/shop/${branchId}/products?search=${search}&languageId=${lngId}`)
      .then(({ data }) => {
        setLoading(false);
        setProducts(data.results.filter(({ deletedAt }) => !deletedAt));
      })
      .catch((err) => {
        setLoading(false);
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        //TODO error
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, 300);
  const onCallWaiter = useDebouncedCallback(() => {
    api
      .post(`/shop/${branchId}/call`, {
        tableId,
      })
      .then(() => {
        const audio = new Audio(sound);
        const info = t('shop.callWaiter');
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
      })
      .catch((err) => {
        //TODO error
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, 300);

  useEffect(() => {
    if (!search) {
      setProducts([]);
      return setSearch('');
    }

    setLoading(true);
    onSearch(search);
  }, [search, onSearch]);

  return (
    <>
      <div className={cn('shop_header', { 'shop_header--dark': false })}>
        <div className='shop_header_in'>
          <div className='shop_header_in_logo'>
            {list?.src && <img src={list?.src} alt='qrrobo' />}
            {!list?.src && <Loading />}
          </div>
          <div
            className='shop_header_in_search'
            onClick={() => setIsSearching(true)}
          >
            <RiSearchLine />
          </div>
          <div className='shop_header_in_actions'>
            <span onClick={onCallWaiter}>
              <BiSolidBellRing />
            </span>
            <span onClick={() => setShowWiFiOpen(true)}>
              <FaWifi />
            </span>
            <span onClick={() => setShowSignUpPage(true)}>
              <BiSolidUser />
            </span>
            {lng && (
              <div
                className='shop_header_in_actions_lng'
                onClick={() => setShowLanguageDropDown(!showLanguageDropDown)}
              >
                <img
                  src={`https://hatscripts.github.io/circle-flags/flags/${lng?.toLowerCase()}.svg`}
                  alt={lng}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ShopHeaderLng
        show={showLanguageDropDown}
        setShow={setShowLanguageDropDown}
        languages={languages}
        setLngId={setLngId}
        setLng={setLng}
      />
      <ShopHeaderSearch
        isSearching={isSearching}
        setSearch={setSearch}
        setIsSearching={setIsSearching}
        loading={loading}
        products={products}
        search={search}
        isDarkMode={false}
      />
      <ShopHeaderWiFi
        show={showWiFiOpen}
        setShow={setShowWiFiOpen}
        isDarkMode={false}
      />
      <ShopHeaderProfile
        setShow={setShowSignUpPage}
        show={showSignUpPage}
        isDarkMode={false}
      />
    </>
  );
}

export default memo(ShopHeader);
