import './index.scss';
import './utils/i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './context/auth/auth-provider';
import ShopProvider from './context/shop/shop-provider';
import PreferencesProvider from './context/preferences/preferences-provider';
import TerminalProvider from './context/terminal/terminal-provider';
import SocketProvider from './context/socket/socket-provider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserProvider from './context/user/user-provider';
import ColorProvider from './context/color/color-provider';
import MenuProvider from './context/menu/menu-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <GoogleOAuthProvider clientId='415300918056-c57o0ct3o13211inm1m33ere21cjgk68.apps.googleusercontent.com'>
      <SocketProvider>
        <MenuProvider>
          <PreferencesProvider>
            <ShopProvider>
              <UserProvider>
                <TerminalProvider>
                  <BrowserRouter>
                    <ColorProvider>
                      <App />
                    </ColorProvider>
                  </BrowserRouter>
                </TerminalProvider>
              </UserProvider>
            </ShopProvider>
          </PreferencesProvider>
        </MenuProvider>
      </SocketProvider>
    </GoogleOAuthProvider>
  </AuthProvider>
);
