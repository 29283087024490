import React, { memo, useCallback, useMemo, useState } from 'react';
import Table from '../../../../components/table';
import NoData from '../../../../components/no-data';
import api from '../../../../utils/api';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import DropDown from '../../../../components/drop-down';

function Tablets({
  setAddNewTablet,
  addNewTablet,
  mode,
  addNewBranch,
  setTablets,
  tablets,
  loading,
  branches,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [selectedBranchField, setSelectedBranchField] = useState(null);

  const TabletsTitles = useMemo(
    () => [
      {
        name: t('components.tables.tablet'),
        size: 200,
      },
      {
        name: t('components.tables.branch'),
      },
    ],
    [t]
  );

  const onCreateTablet = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      if (!name) {
        //TODO error
        return setError('Name cannot be empty!');
      }
      if (!selectedBranchField) {
        //TODO error
        return setError('Branch cannot be empty!');
      }

      const data = {
        name: name,
        branchId: selectedBranchField ? selectedBranchField.id : '',
      };

      api
        .post('/tablets', data)
        .then(({ data }) => {
          setAddNewTablet(false);
          setError(null);
          setName('');
          setSelectedBranchField(null);

          const audio = new Audio(sound);
          const info = t('notifications.tabletCreated');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setTablets([
            ...tablets,
            {
              ...data,
              label: data.name,
              value: data.id,
            },
          ]);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          //TODO error
        });
    },
    [selectedBranchField, t, setTablets, setAddNewTablet, tablets, name]
  );

  return (
    <>
      {!loading &&
        !addNewTablet &&
        !addNewBranch &&
        mode === 'tablets' &&
        !!tablets.length && (
          <Table
            route={false}
            titles={TabletsTitles}
            rows={
              tablets.map((tablet) => ({
                id: tablet.id,
                name: <p>{tablet.name}</p>,
                branch: !tablet.branch?.deletedAt ? (
                  <p>{tablet.branch?.name} </p>
                ) : (
                  <p>
                    {`${tablet.branch?.name}`}
                    <span className=''>{t('general.disable')}</span>
                  </p>
                ),
              })) || []
            }
            hiddenFields={['id']}
            loading={false}
          />
        )}
      {!loading &&
        !addNewTablet &&
        !addNewBranch &&
        mode === 'tablets' &&
        !tablets.length && <NoData title={t('noData.tablets')} />}
      {!addNewBranch && addNewTablet && (
        <form className='form' onSubmit={onCreateTablet}>
          <div className='form_columns'>
            <div className='form_column'>
              <label htmlFor=''>{t('components.tables.name')}</label>
              <input
                type='text'
                value={name}
                maxLength={50}
                placeholder={t('components.tables.name')}
                onChange={(e) => {
                  setError(null);
                  setName(e.target.value);
                }}
              />
            </div>
            <div className='form_column'>
              <label htmlFor=''>{t('routes.branches')}</label>
              <DropDown
                placeholder={t('routes.branches')}
                options={branches.map((branch, index) => ({
                  option: branch,
                  el: <li key={index}>{branch.label}</li>,
                }))}
                value={selectedBranchField}
                onChange={(e) => setSelectedBranchField(e)}
                position='bottom'
                loading={false}
              />
            </div>
          </div>
          <div className='form_error form_error--left'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
              {t('general.save')}
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export default memo(Tablets);
