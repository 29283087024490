import React, { memo, useCallback, useMemo, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { BiReset } from 'react-icons/bi';
import Table from '../../../../components/table';
import NoData from '../../../../components/no-data';
import PropTypes from 'prop-types';
import Warning from '../../../../components/warning';
import api from '../../../../utils/api';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import DropDown from '../../../../components/drop-down';

function Areas({
  selectedArea,
  setSelectedArea,
  areaIsEditing,
  setAreaIsEditing,
  setAddNewArea,
  addNewArea,
  tableMode,
  tableIsEditing,
  addNewTable,
  setAreas,
  areas,
  loading,
  branches,
  integrations,
  onRefreshTables,
}) {
  const { t } = useTranslation();
  const [areaName, setAreaName] = useState('');
  const [warning, setWarning] = useState(false);
  const [selectedBranchField, setSelectedBranchField] = useState(null);
  const [error, setError] = useState(null);

  const AreasTitles = useMemo(
    () => [
      {
        name: t('components.tables.area'),
        size: 200,
      },
      {
        name: t('components.tables.branch'),
        size: 300,
      },
      {},
    ],
    [t]
  );

  const onCreateArea = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      if (!areaName) {
        //TODO error
        return setError('Name cannot be empty!');
      }
      if (!selectedBranchField) {
        //TODO error
        return setError('Branch cannot be empty!');
      }

      const data = {
        name: areaName,
        branchId: selectedBranchField ? selectedBranchField.id : '',
      };

      api
        .post('/areas', data)
        .then(({ data }) => {
          setAddNewArea(false);
          setError(null);
          setAreaName('');
          setSelectedBranchField(null);

          const audio = new Audio(sound);
          const info = t('notifications.areaCreated');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();

          setAreas([
            ...areas,
            {
              ...data,
              label: data.name,
              value: data.id,
            },
          ]);
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          //TODO error
        });
    },
    [selectedBranchField, t, setAreas, setAddNewArea, areas, areaName]
  );
  const onAreaDelete = useCallback(() => {
    api
      .delete(`/areas/${selectedArea.id}`)
      .then(() => {
        setWarning(false);

        setAreas(
          areas.map((area) => {
            if (selectedArea.id === area.id) {
              return {
                ...area,
                deletedAt: new Date(),
              };
            }
            return area;
          })
        );
        onRefreshTables();

        const audio = new Audio(sound);
        const info = `${selectedArea.name} ${t('notifications.areaDeleted')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
      })
      .catch((err) => {
        //TODO error
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t, areas, setAreas, selectedArea, onRefreshTables]);
  const onUnfreezeArea = useCallback(
    (area) => {
      api
        .put(`/areas/${area.id}`, {
          restore: true,
        })
        .then((res) => {
          setError(null);
          setAreas(
            areas.map((_area) => {
              if (area.id === _area.id) {
                return {
                  ...res.data,
                  deletedAt: null,
                  label: res.data.name,
                  value: res.data.id,
                };
              }
              return _area;
            })
          );
          onRefreshTables();
          const audio = new Audio(sound);
          const info = `${area.name} ${t('notifications.areaActivated')}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
        })
        .catch((err) => {
          //TODO error
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [t, areas, setAreas, onRefreshTables]
  );

  return (
    !loading && (
      <>
        {!!branches.length && (
          <>
            {!tableIsEditing &&
              !areaIsEditing &&
              !addNewArea &&
              !addNewTable &&
              tableMode === 'areas' &&
              !areas[0]?.branch?.deletedAt &&
              !!areas.length && (
                <Table
                  route={false}
                  titles={AreasTitles}
                  hiddenFields={['id']}
                  loading={false}
                  rows={
                    areas.map((area) => ({
                      id: area.id,
                      name: <p>{area?.name}</p>,
                      branch: <p>{area?.branch?.name}</p>,
                      actions: (
                        <div className='page_in_content_actions'>
                          {/*{!area.deletedAt &&*/}
                          {/*  integrations &&*/}
                          {/*  !integrations.length && (*/}
                          {/*    <button*/}
                          {/*      className='page_in_content_actions_btn page_in_content_actions_btn--edit'*/}
                          {/*      onClick={() => {*/}
                          {/*        setSelectedArea(area);*/}
                          {/*        setAddNewArea(false);*/}
                          {/*        setWarning(false);*/}
                          {/*        setAreaIsEditing(true);*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      <AiTwotoneEdit /> {t('general.edit')}*/}
                          {/*    </button>*/}
                          {/*  )}*/}
                          {area.deletedAt &&
                            integrations &&
                            !integrations.length && (
                              <button
                                onClick={() => onUnfreezeArea(area)}
                                className='page_in_content_actions_btn page_in_content_actions_btn--reset'
                              >
                                <BiReset />
                              </button>
                            )}
                          {!area.deletedAt &&
                            integrations &&
                            !integrations.length && (
                              <button
                                onClick={() => {
                                  setSelectedArea(area);
                                  setAddNewArea(false);
                                  setAreaIsEditing(false);
                                  setWarning(true);
                                }}
                                className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                              >
                                <IoMdClose />
                              </button>
                            )}
                        </div>
                      ),
                    })) || []
                  }
                />
              )}
            {!tableIsEditing &&
              !areaIsEditing &&
              !addNewArea &&
              !addNewTable &&
              tableMode === 'areas' &&
              areas[0]?.branch?.deletedAt &&
              !!areas.length && <NoData title={t('noData.branchIsDisabled')} />}
            {!tableIsEditing &&
              !areaIsEditing &&
              !addNewArea &&
              !addNewTable &&
              tableMode === 'areas' &&
              !areas.length && <NoData title={t('noData.areas')} />}
            {!tableIsEditing &&
              !areaIsEditing &&
              !addNewTable &&
              addNewArea && (
                <form className='form' onSubmit={onCreateArea}>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor=''>{t('components.tables.name')}</label>
                      <input
                        type='text'
                        value={areaName}
                        maxLength={50}
                        placeholder={t('components.tables.name')}
                        onChange={(e) => {
                          setError(null);
                          setAreaName(e.target.value);
                        }}
                      />
                    </div>
                    <div className='form_column'>
                      <label htmlFor=''>{t('routes.branches')}</label>
                      <DropDown
                        placeholder={t('routes.branches')}
                        options={branches.map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                        value={selectedBranchField}
                        onChange={(e) => setSelectedBranchField(e)}
                        position='bottom'
                        loading={false}
                      />
                    </div>
                  </div>
                  <div className='form_error form_error--left'>
                    {error && (
                      <div className='form_error_name'>
                        <PiWarningOctagonDuotone />
                        <p>{error}</p>
                      </div>
                    )}
                  </div>
                  <div className='form_actions'>
                    <button className='form_actions_btn' type={'submit'}>
                      {t('general.save')}
                    </button>
                  </div>
                </form>
              )}
            {warning && (
              <Warning
                description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${selectedArea.name}`}
                onCancel={() => setWarning(false)}
                onSubmit={onAreaDelete}
              />
            )}
          </>
        )}
        {!branches.length && <NoData title={t('noData.branches')} />}
      </>
    )
  );
}

Areas.propTypes = {
  addNewArea: PropTypes.bool.isRequired,
  tableMode: PropTypes.string.isRequired,
  setAddNewArea: PropTypes.func.isRequired,
  areaIsEditing: PropTypes.bool.isRequired,
  setAreaIsEditing: PropTypes.func.isRequired,
  selectedArea: PropTypes.object,
  setSelectedArea: PropTypes.func.isRequired,
  tableIsEditing: PropTypes.bool.isRequired,
  addNewTable: PropTypes.bool.isRequired,
  setAreas: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onRefreshTables: PropTypes.func.isRequired,
};

export default memo(Areas);
