import './index.scss';
import { memo, useState } from 'react';
import cn from 'classnames';
import ShopCheckout from '../index';
import { useTranslation } from 'react-i18next';
import { RiBillLine } from 'react-icons/ri';

function ShopCheckoutButton({ tableId, branchId, lngId }) {
  const { t } = useTranslation();
  const [checkoutIsOpen, setCheckoutIsOpen] = useState(false);

  return (
    <>
      <div
        className={cn('shop_checkout_button shop_checkout_button--show')}
        onClick={() => setCheckoutIsOpen(true)}
      >
        <div
          className={cn('shop_checkout_button_in', {
            'shop_checkout_button_in--dark': false,
          })}
        >
          <p>
            {t('shop.billing')} <RiBillLine />
          </p>
        </div>
      </div>
      {checkoutIsOpen && (
        <ShopCheckout
          setCheckoutIsOpen={setCheckoutIsOpen}
          branchId={branchId}
          tableId={tableId}
          lngId={lngId}
          isDarkMode={false}
        />
      )}
    </>
  );
}

export default memo(ShopCheckoutButton);
